#filterCont{
    width: fit-content;
    margin: 0 0vw 0 0.5vw;
    
    border-radius: 1vw;
    border-bottom: 0.1vh solid #150050;
    box-shadow: 0 0.2vh 0.2vh #150050;
}

#filterSelect{
    width: 8vw;
    height: 4vh;

    border: none;

    color: #F0E9D2;
    background-color: transparent;


    text-align: center;
    
}
#filterSelect:focus{
    outline: none;
}
#filterSelect:hover{
    cursor: pointer;
    color: #F1D6AB;
}

.filterOption{
    border: none;
    background-color: black;
    color: #F0E9D2;
}