#bigPostCont{
    display: flex;
    justify-content: space-evenly;

    width: 99vw;
    height:100%;

    color: #F0E9D2;
}

#bigImgsCont{
    position: sticky;
    top: 0;
    display: grid;
    grid-auto-columns: 3.9vw;
    grid-auto-rows: 5vh;
    width: 79vw;
    height: 100vh;
}

#backLink{
    text-decoration: none;

    grid-column: 1/4;
    grid-row: 1/2;
  }
#backBut,#backButWoImgs{
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 11vw;
  height: 5vh;
  margin-left: 1vw;

  border: none;
  border-top-left-radius: 1vw;
  border-bottom-left-radius: 1vw;


  color: #F0E9D2;
  background-color: #11101F;


  font-size: 1.3vw;
  font-weight: bold;
  text-decoration: none;

  cursor: pointer;
  transition: 0.5s;

}
#backBut:hover,#backButWoImgs:hover{
  box-shadow: -12vw 0vh 0 0 #864876 inset;

  transition: 0.5s;
}
#backBut > svg ,#backButWoImgs > svg{
  position: relative;
  left: -0vw;
  transition: 0.5s;
}
#backBut:hover > svg,#backButWoImgs:hover > svg{
  position: relative;
  left: -2vw;

  transition: 0.5s;
}
#backButWoImgs{
    background-color: transparent;
}



#CarouselButLeft{
    grid-column: 1/2;
    grid-row: 8/13;

    border-top-right-radius: 1vw;
    border-bottom-right-radius: 1vw;
}
#CarouselButRight{
    grid-column: 19/20;
    grid-row: 8/13;

    border-top-left-radius: 1vw;
    border-bottom-left-radius: 1vw;
}
#CarouselButLeft,#CarouselButRight{
    border: none;

    background-color: #86487611;
    box-shadow: 0 0 0 0 #864876 inset;
    color: #F0E9D2;

    font-size: 3vw;

    transition: ease-out 0.5s;
}
#CarouselButLeft:hover,#CarouselButRight:hover{
    box-shadow: 0 -30vh 0 0 #864876 inset;
    color: #F8F8F8;
    transition: 0.5;
}

#indexImg{
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    grid-column: 9/12;
    grid-row: 1/2;

    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;

    background-color: #86487611;

    text-align: center;
    font-size: 1.5vw;
}

#arrImgCont{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 2/19;
    grid-row: 2/20;

    
    
}
.cardPostImg{
    max-width: 72vw;
    max-height: 95vh;
    margin: auto;
}   


#infoCont{
    width: 24vw;
    height: fit-content;
    min-height: 96vh;

    margin: 2vh 0vh 2vh 2vh;

    border-radius: 1vw;

    background-color: #19182D;
    color: #F0E9D2;
}
#longInfoCont{
    width: 40vw;
    height: fit-content;
    min-height: 96vh;

    margin: 2vh 0vh 2vh 2vh;

    border-radius: 1vw;

    background-color: #19182D;
    color: #F0E9D2;
}

#userInfoCont{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 8vh;

    border-top-left-radius: 1vw;
    border-top-right-radius: 1vw;
    box-shadow: 0vw 0.5vh 0.5vh #6000947b;
    border-bottom: 0.2vh solid #6000945e;

    background-color: #1F1D36;
}
#posterImg{
    height: 7vh;
    width: 7vh;
    margin: 0.5vh 10% 0.5vh 10%;

    border-radius: 7vh;
}
#posterName{
    width: fill;
    margin: 0;

    color: #864879;

    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.5vw;
}

#infoCont > h1, #longInfoCont > h1{
    margin: 2vh auto 2vh auto;
    width: fit-content;
    text-align: center;
}

#bigDescriptionCont{
    width: 21vw;
    margin: 3vh auto 3vh auto;
}

#bigAnaliticsCont{
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 5vh;

    box-shadow: 0vw 0.5vh 0.5vh #6000947b;
    border-bottom: 0.2vh solid #6000945e;

    background-color: #1F1D36;
}

#likesShell, #sharesShell, #favoritesShell{
    display: flex;
    justify-content: space-between;
    width: 3vw;
    height: 3vh;
    margin: auto;
    padding:1vh 0 1vh 0;
}

.notLikedImg ,.notSharedImg{
    color: #F0E9D2;
    cursor: pointer;

    transition: 0.5s;
}

.sharedImg{
    color: #E9A6A6;
    cursor: pointer;

    transition: 0.5s;
}
.likedImg{
    color: #864879;
    cursor: pointer;

    transition: 0.5s;
}



#newCommentaryCont{
    width: 90%;
    height: 16vh;
    margin: 2vh auto;
}

#newCommentInput{
    display: flex;
    flex-direction: column;
    width: 96.5%;
    height: 8vh;

    border-top-left-radius: 0.5vw;
    border-top-right-radius: 0.5vw;
    border: solid 0.2vw #864879;

    background-color: transparent;
    color: #F0E9D2;
    overflow-y: scroll;

    resize: none;

    transition: 1s;
}
#newCommentInput:focus{
    height: 12vh;
    outline: none;

    transition: 1s;
}
#newnewCommentInput::-webkit-scrollbar{
    width: 5%;
}
#newCommentInput::placeholder{
    color: #f0e9d29a;
    text-align: center;
}

#newCommentaryBut{
    width: 99.5%;
    height: 4vh;
    margin-top: 0;

    border: none;
    border-bottom-left-radius: 0.5vw;
    border-bottom-right-radius: 0.5vw;

    box-shadow: 0 0vh 0 0 #864876 inset;
    background-color: #19182D;
    color: #F0E9D2;

    transition: 0.5s;
}
#newCommentaryBut:hover{
    box-shadow: 0 5vh 0 0 #864876 inset;
    color: #F8F8F8;
    transition: 0.5s;
}




.commentCont{
    width: 98%;
    height: fit-content;

    margin: 2vh auto 2vh auto;
}
.commentUser{
    display: flex;
    flex-direction: row;
    
    width: 100%;
    height: 5vh;
    margin-bottom: 1vh;

    border-top-left-radius: 1vw;
    border-top-right-radius: 1vw;
    box-shadow: 0vw 0.5vh 0.5vh #6000947b;
    border-bottom: 0.2vh solid #6000945e;
}
.commentUser > img{
    width: 5vh;
    height: 4vh;
    margin: 0.5vh 5% 0.5vh 5%;

    border-radius: 4vh;
}
.commentName{
    align-self: center;

    width: fill;
    margin: 0;

    color: #864879;

    text-decoration: none;
    font-weight: bold;   
}
.commentaryShell{
    width: 95%;
    height: fit-content;
    margin: auto;
    padding-bottom:1vh;
}

.cardPostPrice{
    width: fit-content;
    margin: 1vh auto 1vh auto;

    color: #E3B04B;
}


@media screen and (max-width:1024px){
    #bigPostCont{
        flex-direction: column-reverse;
        justify-content: center;
    }
    #bigImgsCont{
        position: inherit;
        display: grid;
        grid-auto-columns: 5vw;
        grid-auto-rows: 2.5vh;
        width: 100vw;
        height: 50%;
    }

    #backLink{
        text-decoration: none;
    
        grid-column: 1/4;
        grid-row: 1/3;

        font-size: 3vw;
    }
    #backBut,#backButWoImgs{
        font-size: 2.5vw;
    }


    #CarouselButRight{
        grid-column: 20/21;
    }
    #indexImg{
        grid-column: 9/13;
        grid-row: 1/3;

        font-size: 3vw;
    }
    #arrImgCont{
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: 2/20;
        grid-row: 2/20;      
    }
    .cardPostImg{
        max-width: 90vw;
        max-height: 95vh;
        margin: auto;
    }  


    #infoCont,#longInfoCont{
        width: 90vw;
        height: fit-content;
        min-height: 52.5vh;
    
        margin: auto;
    }
    #posterName{
        font-size: 5vw;
    }
    #infoCont > h1, #longInfoCont > h1{
        font-size: 5vw;
    }
    #bigDescriptionCont{
        width: 21vw;
        margin: 3vh auto 3vh auto;

        font-size: 3vw;
    }


    #bigAnaliticsCont{
        display: flex;
        flex-direction: row;
    
        width: 100%;
        height: 5vh;
    
        box-shadow: 0vw 0.5vh 0.5vh #6000947b;
        border-bottom: 0.2vh solid #6000945e;
    
        background-color: #1F1D36;
    }
    #likesShell, #sharesShell, #favoritesShell{
        display: flex;
        justify-content: space-between;
        width: 5vw;
        height: 3vh;
        margin: auto 5vw auto 5vw;
        padding:1vh 0 1vh 0;

        font-size: 2vh;
    }


    #newCommentInput, #newCommentaryBut{
        font-size: 2.5vw;
    }
    
    #newCommentaryBut{
        width: 97.5%;
    }



    .commentName{
       font-size: 3vw; 
    }
    .commentaryShell{
        font-size: 3vw;
    }
    
    .cardPostPrice{
        width: fit-content;
        margin: 1vh auto 1vh auto;
    
        color: #E3B04B;
    }
}