#FeedContainer{
    display: flex;
    flex-direction: column;
    width: 60vw;
    min-height: 78vh;

    border-radius: 0.5vw;
    border-left: 2px solid rgb(50, 50, 50);
    border-right: 2px solid rgb(50, 50, 50);
}

#cardUserArrCont{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

#statusUsersCard{
    width: 15vw;
    margin-left: 5vw;
    padding-left: 1vw;

    border-bottom: 0.2vh solid #E3B04B;

    color: #F0E9D2;

    text-align: left;
    font-size: 1.8vw;
}

#loadButUsers{
    width: 100%;
    height: 4vh;

    border: none;
    border-bottom-left-radius: 2vw;
    border-bottom-right-radius: 2vw;
    border: 0.2vw solid transparent;
    border-top: 0.1vh solid rgb(63, 51, 81);

    box-shadow: inset 0vw 0.2vh 0.5vh rgb(63, 51, 81);
    background-color: transparent;
    color: #F0E9D2;

    text-align: center;
    font-size: 1.1vw;
    font-weight: bold;

    cursor: pointer;

    transition: ease-out 0.5s;
}
#loadButUsers:hover{
    height: 5vh;

    border-bottom-left-radius: 0.5vw;
    border-bottom-right-radius: 0.5vw;
    border: 0.2vw solid #E9A6A6;

    box-shadow: inset 0vw 6vh #E9A6A6;
    background-color: #1F1D36;
    color: #11101F; 

}



.feedStatus{
    margin: auto;

    color: #F0E9D2;

    font-size: 3vw;
    text-align: center;
}




@media screen and (max-width:1024px){
    #FeedContainer{
        width: 90vw;
        min-height: 70vh;
        margin: 0 auto 0 auto;
    }


    #cardUserArrCont{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    #statusUsersCard{
        width: 80vw;
        font-size: 3vw;
    }
    #loadButUsers{
        font-size: 2vw;
    }
}
