#BandejaCont{
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100vh;

    border-radius: 0.5vw;

    color: #F0E9D2;
    background-color: #864879;
}

#chatMenu{
    width: 19.75%;
    height: 100%;
    margin-right: 0.125%;

    border-radius: 0.5vw;

    background-color: #11101F;

    overflow-y: scroll;
}

#backLink{
    text-decoration: none;
  }
#backBut{
  position: absolute;
  top: -0vh;
  left: -0vw; 

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 6vw;
  height: 5.4vh;

  border: none;
  border-top-right-radius: 1vw;
  border-bottom-right-radius: 1vw;

  color: #F0E9D2;
  background-color: #11101F;


  font-size: 1.3vw;
  font-weight: bold;
  text-decoration: none;

  cursor: pointer;
  transition: 0.5s;

}
#backBut:hover{
  box-shadow: -10vw 0vh 0 0 #864876 inset;

  transition: 0.5s;
}
#backBut > svg{
  position: relative;
  left: -0vw;
  transition: 0.5s;
}
#backBut:hover > svg{
  position: relative;
  left: -2vw;

  transition: 0.5s;
}



#chatBox{
    width: 59.5%;
    height: 100%;   
    margin: 0 0.125% 0 0.125%;

    border-radius: 0.5vw;
}
#chatBoxTop{
    width: 100%;
    height: 78.75vh;
    padding-top: 1vh;

    border-radius: 0.5vw;
    border-bottom: 0.25vh solid #151425;

    background-color: #11101F;

    overflow-y: scroll;
}
#chatFin{
    width: 100%;
    height: fit-content;

    /* border-bottom: 1vh solid #151425; */
    border-radius: 0.5vw;

    background-color: #11101F;

    overflow: hidden;
}
#textArea{
    width: 90%;
    height: 14.75vh;
    padding: 0.5vh 5% 0 5%;

    border: none;
    border-radius: 0.5vw;
    border-top: 0.25vh solid #864879;

    background-color: #11101F;
    color: #F0E9D2;

    resize: none;
    transition: 0.2s;
}
#textArea:focus{
    outline: none;

    background-color: #131222;

    transition: 0.2s;
}
#textArea:focus + #chatBoxTop{
    height: 68.75;
}

#button{
    align-items: center;

    width: 100%;
    height: 4vh;

    background-color: #864879;
    color: #F0E9D2; 

    border: none;
    border-bottom-left-radius: 0.5vw;
    border-bottom-right-radius: 0.5vw;
    
    text-align: center;

    cursor: pointer;
    transition: 0.2s;
}
#button:hover{

    background-color: #E9A6A6;
    color: #11101F;

    font-size: 1.2vw;

    transition: 0.2s;
}
#button > svg{
    color: #864879;
    font-size: 0vw;
    transition: 0.2s;
}
#button:hover > svg{
    font-size: 1.2vw;
    transition: 0.2s;
}

#sinConversacion{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 59.5vw;
    height: 100%;   
    margin: 0 0.125% 0 0.125%;

    border-radius: 0.5vw;

    background-color: #11101F;

    font-size: 2.5vw;
}



#ChatOnline{
    width: 20.75%;
    height: 100%;
    margin-left: 0.25%;

    /* border-left: 0.5vw solid #151425; */
    border-radius: 0.5vw;

    background-color: #11101F;

    overflow-y: scroll;
}
#ChatOnline > h3,#chatMenu > h3{
    text-align: center;
    border-bottom: 0.2vh solid #E9A6A6;
    margin-bottom: 0;
}



@media screen and (max-width:1025px){
    #BandejaCont{
        flex-direction: column;
        border-radius: 0vw;
    }

    #buttons{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        height: 5vh;
        width: 90%;
        padding: 0 5% 0 5%;

        background-color: #19182D;

        font-size: 2vw;
    }
    #backBut{
        position: relative;
      
        width: 28vw;
        height: 4.8vh;
        margin: 0 1vw 0 1vw;

        border: none;
        border-top-right-radius: 1vw;
        border-bottom-right-radius: 0vw;
        border-top-left-radius: 1vw;
        border-bottom: 0.2vh solid #3F3351;
      
        color: #F0E9D2;
        background-color: #11101F;
           
        font-size: 2vw;     
      }
    .optionChat{
      width: 28vw;
      height: 4.8vh;
      margin: 0 1vw 0 1vw;

      border: none;
      border-top-right-radius: 1vw;
      border-top-left-radius: 1vw;

      border-bottom: 0.2vh solid #3F3351;
      background-color: #1C1A31;

      color: #F0E9D2;

      font-size: 2vw;
      font-weight: bold;
    }

    #chatMenu, #chatBox, #ChatOnline{
        width: 100%;
        margin: 0;
    }
    #ChatOnline > h3,#chatMenu > h3{
      font-size: 3vw;
    }  

    #chatBox{
        flex-direction: column;
    }
    #chatBoxTop{
        height: 73.75vh;
    }
    #textArea{
        width: 90%;
        height: 14.75vh;
        padding: 0.5vh 5% 0 5%;
    
        font-size: 2vw;
    }
    #button{
        font-size: 3vw;
    }
}