#upperCont{
   width: 100%; 
}


#backLink{
    text-decoration: none;
  }
#backBut{
  position: absolute;
  top: -0vh;
  left: -1vw; 

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 10vw;
  height: 5vh;

  border: none;
  border-bottom: 0.2vw solid #864879;
  border-radius: 1vw;

  color: #F0E9D2;
  background-color: #11101F;


  font-size: 1.3vw;
  font-weight: bold;
  text-decoration: none;

  cursor: pointer;
  transition: 0.5s;

}
#backBut:hover{
  box-shadow: -10vw 0vh 0 0 #864876 inset;

  transition: 0.5s;
}
#backBut > svg{
  position: relative;
  left: -0vw;
  transition: 0.5s;
}
#backBut:hover > svg{
  position: relative;
  left: -2vw;

  transition: 0.5s;
}


#container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;

    height: fit-content;
}

#bannerCont{
    margin: auto;
    width: 100%;
    height: 30vh;

    overflow-y: hidden;
}
#banner{
    width: 100%;
    height: auto;
}


#profileSection {
    display: flex;
    align-items: center;

    width: 80%;
    height: 20vh;
    padding: 3vh 10% 3vh 10%;

    background-color: #1C1A31;
    color: #F0E9D2;

    border-bottom: 0.5vh solid rgb(63, 51, 81);
    /* border-top: 0.5vh solid #11101F; */
}

#artistBadge{
    position: relative;
    top: 2vh;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 2.2vw;
    height: 2.2vw;

    border-radius: 3vw;
    border: 0.3vw solid #E3B04B;

    color: #E3B04B;

    font-size: 1.7vw;

    cursor: context-menu;
}

#leftProfileSect{
    width: 10.8vw;
    height: fit-content;

    margin-right: 2vw;
}

#profPhotoCont{
    position: relative;
    top: -8.5vh;
    width: 10vw;
    height: 10vw;
    
    border-radius: 20vw;
    border: 0.4vw solid #864879;
    overflow: hidden;
}
#profPhotoCont > div{
    position: relative;
    /* top: -25%; */
    left: -25%;
    width: 15vw;
    height: fit-content;
}

#profilePhoto{
    width: 70%;
    max-width: 20vw;
    margin-left: 2.5vw;
    height: auto;
}

#followButton,#followingButton{
    position: relative;
    top: -3vh;
    

    width: 10.8vw;
    height: 5vh;

    border-radius: 1vw;

    box-shadow: 0 0vh inset #E9A6A6;

    cursor: pointer;

    text-align: center;
    font-size: 1.2vw;
    font-weight: bold;
}


#followButton{
    border: 0.2vw solid #3F3351;

    background-color: #3F3351;
    color: #3F3351;

    transition: 0.5s;
}   
#followButton:hover{
    border-radius: 0.5vw;
    border: 0.2vw solid #E9A6A6;

    box-shadow: 0 -5vh #864879 inset;
    background-color: #E9A6A6;
    color: #F0E9D2;

    transition: 0.5s;
}
#followButton > svg{
    position: relative;
    align-self: center;
    left: 2.2vw;
    color: #11101F;
    transition: 0.4s;
}
#followButton:hover > svg{
    left: -1vw;
    color: #E9A6A6;
    transition: 0.4s;
}



#followingButton{
    border: 0.2vw solid #E9A6A6;

    background-color: #E9A6A6;
    color: #E9A6A6;

    transition: 0.5s;
}
#followingButton > svg{
    position: relative;
    align-self: center;
    left: 3vw;

    color: #11101F;

    transition: 0.5s;
}
#followingButton:hover{
    border-radius: 0.5vw;
    border: 0.2vw solid #3F3351;

    box-shadow: 0 -5vh #864879 inset;
    background-color: #E9A6A6;
    color: #F0E9D2;

    transition: 0.5s;
}
#followingButton:hover > svg{
    left: -1vw;

    color: #E9A6A6;

    transition: 0.5s;
}

#infoCont{
    width: fit-content;
    margin-left: 1vw;
    margin-right: 2vw;
}
#followsCont{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
#followsLink button{
    background-color: transparent;
    color: #F0E9D2;
    border: none;
    height: 5vh;
    width: 10vw;
}
#followsLink button:hover{
    background-color: #e9a6a693;
}
#followsLink button:focus{
    border-bottom-style: solid;
    color: white;
}



#donationCont{
    display: grid;
    grid-auto-columns: 4vw;
    grid-auto-rows: 4vh;

    width: 16vw;
    height: 4vh;

    color: #F0E9D2;
}
#longDonationBut{
    grid-column: 1/5;
    grid-row: 1/2;

    width: 105%;
}
#shortDonationBut{
    grid-column: 1/5;
    grid-row: 1/2;
    
    width:25%;   
}
#longDonationBut,#shortDonationBut{ 
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-evenly;

    border: none;
    border-radius: 0.5vw;

    background-color: #864879;
    color: #F0E9D2;

    transition: 0.2s;
}
#longDonationBut:hover,#shortDonationBut:hover{ 
    border-radius: 0.2vw;

    background-color: #E9A6A6;
    color: #151425;
    
    transition: 0.2s;
}
#butSvgDecoLeft{
    font-size: 2vw;
    
    transition: 0.2s;
}
#butSvgDecoRight{
    font-size: 2vw;

    transition: 0.2s;
}
#longDonationBut:hover > #butSvgDecoLeft{
    padding-right: 2vw;
    transition: 0.2s;
}
#longDonationBut:hover > #butSvgDecoRight{
    padding-left: 2vw;
    transition: 0.2s;
}

#postsLink {
    background-color: transparent;
    color: #ffffff;
    font-size: 20px;
    text-decoration: none;
    height: 100px;
}



#postsButtons {
    display: flex;
    justify-content: center;

    
    width: 100%;
    height: 5vh;

    background-color: #151425;
    box-shadow: inset 0vw 0.2vh 0.5vh rgb(63, 51, 81);
}
#postsLink {
    background-color: transparent;
    color: #ffffff;
    font-size: 20px;
    text-decoration: none;
    height: fit-content;
}
#postsLink button{
    background-color: transparent;
    color: #ffffff;
    border: none;
    height: 5vh;
    width: 15vw;
}
#postsLink button:hover{
    background-color: #e9a6a693;
}
#postsLink button:focus{
    border-bottom-style:solid;
}



#userBoardCont{
    width: 100%;
    height: fit-content;
    min-height: 30vh;

    box-shadow: inset 0vw 0.2vh 0.5vh rgb(63, 51, 81);
    background-color: #11101F;
}

#userStatus{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100wv;
    height: 100vh;

    font-size: 5vw;
    text-align: center;
    color: #E9A6A6;
}





@media screen and (max-width:1024px){

    #backBut{
      width: 30vw;
      height: 5vh;

      font-size: 3vw;
    }
    #bannerCont{
        height: 20vh;
    }


    #profileSection {
        width: 100%;
        height: 20vh;
        padding: 3vh 0% 3vh 0%;
    }

    
    #artistBadge{
        top: -3vh;
        left: 5vw;
  
        width: 4vw;
        height: 4vw;  
        font-size: 3.5vw;
    }

    #leftProfileSect{
        width: 30vw;   
    }
    #profPhotoCont{
        top: -8.5vh;
        left: 5vw;
        width: 20vw;
        height: 20vw;
    }
    #profPhotoCont > div{
        width: 30vw;
    }
    #profilePhoto{
        margin-left: 5vw;
        max-width: 20vw;
    }
    


    #followButton,#followingButton{  
        width: 30vw;
        height: 5vh;

        font-size: 4vw;
    }
    #followingButton > svg{
        left: 10vw;
    }
    #followButton > svg{
        left: 7.2vw;
    }


    #infoCont{
        width: fit-content;
        height: 90%;
    }
    #infoCont > h1{
        font-size: 4vw;
        margin-top: 0vh;
    }
    #infoCont > p{
        font-size: 3vw;
        margin-top: 0vh;
    }
    #followsLink button{
        width: 49%;
        font-size: 2vw;
    }


    #donationCont{
        grid-auto-columns: 6vw;
    
        width: 24vw;
        margin: auto;
    }

    #longDonationBut,#shortDonationBut{ 
        font-size: 2vw;
        font-weight: bold;
    }
    #postsLink > button{
        font-size: 2vw;
    }
    
}