.ChatOnline{
    width: 100%;
    height: 100%;
}

.userOnline{
    width: 100%;
    height: 5vh;

    background-color: #131222;
    border-bottom: 0.2vh solid #E9A6A6;
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 4.5vh;
    height: 4.5vh;

    border-radius: 4.5vw;
    border: 0.25vw solid #1F1D36;

    overflow: hidden;

    transition: 0.2s;
}
.container:hover{
    border: 0 solid #864879;
    width: 5vw;
    height: 5vw;
    transition: 0.2s;
}   


.image{
    width: 8vw;
    height: auto;
}


.name{
    display: flex;
    align-items: center;

    width: 10vw;

    color: #864879;
    font-size: 1.2vw;
    text-align: center;

    overflow: hidden;
}



@media screen and (max-width:1025px){
    .container{
        width: 6vw;
        height: 6vw;
    
        border-radius: 6vw;
    }
     
    .image{
        width: 12vw;
        height: auto;
    }
    
    
    .name{
        width: 50vw;
        font-size: 3vw;
    }
}