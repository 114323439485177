#donationCont{
    display: flex;
    flex-direction: row;

    width: 6vw;
    height: 4vh;

    grid-column: 2/5;
    grid-row: 1/2;
}

#donationInput{
    width: 8vw;
    height: 3.8vh;

    border: none;

    background-color: #131222;
    color: #F0E9D2;
}
#donationInput:focus{
    outline: none;
}

#submitBut{
    width: 4vw;
    height: 4vh;

    border: none;
    border-top-right-radius: 0.5vw;
    border-bottom-right-radius: 0.5vw;

    background-color: #131222;
    color: #F0E9D2;

    text-align: center;

    cursor: pointer;

    transition: 0.2s;
}
#submitBut:hover{
    background-color: #E9A6A6;
    color: #151425;

    transition: 0.2s;
}


@media screen and (max-width:1024px){
    #donationCont{
        width: 20vw;
        height: 4vh;
    
        grid-column: 2/8;
        grid-row: 1/2;
    }
    #donationInput{
        width: 14vw;
        font-size: 2.5vw;
    }
    #submitBut{
        width: 5vw;
        font-size: 2vw;
    }
}