#optionshell{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 63vw;
    padding-top: 3vh;
    margin-bottom: 3vh;
}
#filtersShell{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    width: 24vw;
}

#selectFilter,#selectOrder{
    width: 10vw;
    height: 4vh;
    margin: auto;

    border: none;
    border-top-left-radius: 0.5vw;
    border-top-right-radius: 0.5vw;
    border-bottom: 0.2vh solid #3F3351;

    box-shadow: 0 0 inset #1F1D36;
    background-color: transparent;
    color: #F0E9D2;

    font-size: 1.2vw;
    text-align: center;

    transition: 0.2s;
}

#selectFilter:focus,#selectOrder:focus{
    outline: none;
}
#selectFilter:hover,#selectOrder:hover{


    border-bottom: 0.2vh solid #864879;

    background-color: #0c0b16;
    box-shadow: 0 0 inset #1F1D36;
    color: #E3B04B;
}
.optionFree{
    background-color: #151425;
    border: none;
}
#optionPremium{
    background-color: #E9A6A6;
    color: #11101F;
}
#optionSubscribe{
    background-color: #864879;
    color: #F0E9D2;
}




#insideStatus{
    width: fit-content;
    margin: auto;

    color: #E9A6A6;

    font-size: 3vw;
    
}



#imgsArrCont{
    width: 100%;
    height: fit-content;
}
#imgsArrShell{
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    height: fit-content;
}

.imgShell{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 31vw;
    height: 40vh;

    margin: 1vh 0.8vw 1vh 0.8vw;

    border-radius: 2vw;

    background-color: transparent;

    cursor: pointer;
    transition: 0.2s;
}
.imgShell:hover{
    background-color:#0c0b16;
    border-radius: 1vw;
}
.imgPortfolio{
    max-width: 70%;
    min-width: 20%;

    max-height: 70%;
    min-height: 20%;

    border-radius: 2vw;
    border: 0.1vw solid #151425;
    
    box-shadow: 0 0 #E9A6A6;

    overflow: hidden;   
    transition: 0.2s;
}
.imgPortfolio:hover{
    max-width: 95%;
    max-height: 95%;

    border-radius: 1vw;
    border: 0.2vw solid #3F3351;

    box-shadow: 0 0 0.3vw 0.1vh #3F3351;
}




#loadMore{
    width: 100%;
    height: 10vh;

    border: none;
    border-top: 0.2vh solid #3F3351;
    
    box-shadow: 0 0 inset #864879;
    background: linear-gradient(180deg, rgba(17,16,31,1) 35%, rgba(12,11,22,1) 60%);
    color: #F0E9D2;

    font-size: 1.6vw;

    text-align: center;
    
    cursor: pointer;
    transition: 0.2s;
}
#loadMore:hover{
    outline: none;
    color: #E3B04B;

    box-shadow: 0 10vh inset rgba(12,11,22,1);
    background:rgba(12,11,22,1);
}





#subsContDeco{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 18vw; /*28*/
    height: 3.8vh;
    margin-right: 5vw;
    margin-bottom: 0.4vh;

    box-shadow: 0 0 inset #725AC1;

    border-radius: 1vw;

    background-color: #131222;

    transition: 0.5s;
}
#subsContDeco:hover{
    width: 26vw; 
    height: 4.2vh;

    margin-right: 1vw;
    margin-bottom: 0.0vh;

    border-radius: 2vw;
    box-shadow: 0 5vh inset #131222;
}

#subscriptionButCont{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 17vw;  /*23*/
    height: 4.4vh;

    border-radius: 1vw;
    box-shadow: 0 0 inset #725AC1;
    background-color: #171629;

    transition: 0.5s;

}
#subscriptionButCont:hover{
    width: 23vw; /*23*/
    height: 4.6vh;

    border-radius: 1vw;

    box-shadow: 0 -5vh inset #171629;
}

#subscriptionButCont > span{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 16vw; /*19*/
    height: 4.6vh;

    border-radius: 2vw;

    box-shadow: 0 0 inset #725AC1;
    background-color: #1C1A31;

    transition: 0.5s;
}

#subscriptionButCont > span:hover{
    width: 20vw; /*26*/
    height: 5.1vh;

    border-radius: 0.8vw;
    box-shadow: 0 5vh inset #1C1A31;
}

#subscribeBut{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 15vw; /*16*/
    height: 5vh;

    border-radius: 2vw;
    border: none;
    border-left: 0.5vw solid #3F3351; /*1*/
    border-right: 0.5vw solid #3F3351; /*1*/

    box-shadow: inset 0 0 0 0 #725AC1;
    background-color: #E9A6A6;
    color: #11101F;

    font-size: 1.3vw;
    font-weight: bold;

    transition: 0.5s;
}
#subscribeBut > svg{
    color: #f0e9d2b8;
    transition: 0.5s;
}
#subscribeBut:hover{
    width: 18vw; /*26*/
    height: 5.8vh;
    
    border-radius: 0.2vw;
    border-left: 0.5vw solid #E3B04B; /*1*/
    border-right: 0.5vw solid #E3B04B; /*1*/

    box-shadow: 0 -6vh inset #E3B04B;
    background-color: #E3B04B;
}
#subscribeBut:hover > #leftHeart{
    padding-right: 2vw;
    font-size: 1.5vw;
}
#subscribeBut:hover > #righttHeart{
    padding-left: 2vw;
    font-size: 1.5vw;
}



#subedContDeco{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 26vw; 
    height: 4.2vh;

    margin-right: 1vw;
    margin-bottom: 0.0vh;

    box-shadow: 0 5vh inset #131222;

    border-radius: 2vw;

    background-color: #131222;

    transition: 0.5s;
}
#subscribedButCont{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 23vw; /*23*/
    height: 4.6vh;

    border-radius: 1vw;

    box-shadow: 0 -5vh inset #171629;
    background-color: #171629;

    transition: 0.5s;
}
#subscribedButCont > span{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 20vw; /*26*/
    height: 5.1vh;

    border-radius: 0.8vw;
    box-shadow: 0 5vh inset #1C1A31;
    background-color: #1C1A31;

    transition: 0.5s;
}
#subscribedBut{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 18vw; /*26*/
    height: 5.8vh;
    
    border-radius: 0.2vw;
    border: none;
    border-left: 0.5vw solid #E3B04B; /*1*/
    border-right: 0.5vw solid #E3B04B; /*1*/

    box-shadow: 0 -6vh inset #E3B04B;
    background-color: #E3B04B;
    color: #F0E9D2;

    font-size: 1.3vw;
    font-weight: bold;

    transition: 0.5s;
}
#altLeftHeart{
    padding-right: 2vw;
    font-size: 1.5vw;
}
#altRightHeart{
    padding-left: 2vw;
    font-size: 1.5vw;
}




@media screen and (max-width:1024px){
    #optionshell{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    
        width: 70vw;
        padding-top: 3vh;
        margin-bottom: 3vh;
    }

    #filtersShell{    
        width: 30vw;
    }
    #selectFilter,#selectOrder{
        width: 13vw;
        height: 4vh;
    }


    #subsContDeco{  
        width: 18vw; /*18*/
        margin-right: 10vw;
    }
    #subscriptionButCont{
        width: 17vw;  /*17*/ 
    }
    #subscriptionButCont > span{
        width: 16vw; /*16*/
        height: 4.6vh;
    }
    #subscribeBut{
        width: 15vw; /*15*/
        height: 5vh;
        font-size: 2vw;
    }

    #subedContDeco{
        width: 32vw; 
    }
    #subscribedButCont{
        width: 26vw;
    }
    #subscribedButCont > span{
        width: 22vw;
    }
    #subscribedBut{ 
        width: 19vw; /*26*/
        height: 5.8vh;
        
        font-size: 2vw;
    }

    .imgPortfolio{
        max-width: 90%; 
        max-height: 90%;
        border-radius: 1vw;
    }
}