.conversation{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    width: 100%;
    height: 10vh;

    border-bottom: 0.2vh solid #E9A6A6;

    background-color: #19182D;

    transition: 0.2s;
}
.conversation:hover{
    background-color: #3F3351;
    transition: 0.2s;
}
.conversation:hover > .imgCont{
    border: 0.25vw solid #E9A6A6;
    transition: 0.2s;
}
.conversation:hover > .name{
    color: #E9A6A6;
    transition: 0.2s;
}



.imgCont{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 4.5vw;
    height: 4.5vw;

    border-radius: 4.5vw;
    border: 0.25vw solid #3F3351;

    overflow: hidden;

    transition: 0.2s;
}
.imgCont:hover{
    border: 0 solid #3F3351;
    width: 5vw;
    height: 5vw;
    transition: 0.2s;
}   

.image{
    width: 8vw;
    height: auto;
}




.name{
    display: flex;
    align-items: center;

   width: 10vw;

    color: #864879;
    font-size: 1.2vw;
    text-align: center;

    overflow: hidden;
}


@media screen and (max-width:1025px){
    .imgCont{
    
        width: 10vw;
        height: 10vw;

        border-radius: 20vw;
    }
    .imgCont:hover{
        width: 15vw;
        height: 15vw;
    }   
    
    .image{
        width: 8vw;
        height: auto;
    }

    .name{
        font-size: 3vw;
        width: 50vw;
    }
}