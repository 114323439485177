.card{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 32%;
    height: 9vh;

    margin: 1vh 0.5% 1vh 0.5%;

    border-radius: 1vw; 
    box-shadow: 0vw 0.3vh 0.3vh 0.2vh #6000947b;
    border-bottom: 0.2vh solid #6000945e;

    background-color: #19182D;
    color: #F0E9D2;

    transition: 0.5s;
}
.card:hover{
    border-radius: 0.5vw;
    background-color: #3F3351;

    transition: 0.5vw;
}

.linkShell{
    display: flex;
    flex-direction: row;

    width: 15.1vw;

    text-decoration: none;
    color: #F0E9D2;
}

.imgShell{
    width: 3.5vw;
    height: 3.5vw;
    margin: auto 0.2vw auto 0.5vw;
    
    border-radius: 5vw;
    border: 0.2vw solid #E9A6A6;

    background-color: #864879;
    
    overflow: hidden;

    transition: 0.2s;
}
.imgShell:hover{
    width: 4vw;
    height: 4vw;

    transition: 0.2s;
}
.img{
    width: 4vw;
    height: auto;
}


.namecShell{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 8vw;
    height: 3vh;
    margin: auto;

    overflow: hidden;
}
.fullName{
    font-size: 1.4vw;
}



.followBut{
    width: 5vw;
    height: 8.6vh;
    
    border-radius: 1vw;
    border: 2px solid #1C1A31;
    box-shadow: inset 0 0 0 0 #725AC1;

    color: #725AC1;
    background: transparent;

    text-align: center;
    font-size: 0.9vw;
    font-weight: bold;

    cursor: pointer;
    transition: ease-out 0.5s;
}
   
.followBut:hover {
    border-radius: 0.5vw;

    color: #1C1A31;
    box-shadow: inset 0 -100px 0 0 #E9A6A6;
}
   
.followBut:active {
    transform: scale(0.9);
}




@media screen and (max-width:1024px){
    .card{
        width: 80vw;
        height: 9vh;
    
        margin: 1vh auto 1vh auto;
    }



    .linkShell{
        display: flex;
        flex-direction: row;

        width: 70vw;

        text-decoration: none;
        color: #F0E9D2;
    }

    .imgShell{
        width: 9vw;
        height: 9vw;
        margin: auto 5vw auto 5vw;

        border-radius: 10vw;
        border: 0.2vw solid #E9A6A6;

        background-color: #864879;

        overflow: hidden;

        transition: 0.2s;
    }
    .img{
        width: 14vw;
        height: auto;
    }


    .namecShell{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: initial;

        width: 40vw;
        height: 3vh;
        margin: auto;

        font-size: 3vw;
    }
    .fullName{
        font-size: 3vw;
    }
}