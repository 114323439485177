#container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5%;
    color: white;
}
#container > p{
    text-align: center;
    font-size: 3vw;
    color: #725AC1;
}

#container button {
    background-color: transparent;
    font-size: 18px;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    padding: 0.7em 2em;
    border: 3px solid #725AC1;
    border-radius: 2px;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
    color: #725AC1;
    transition: 0.3s ease all;
    z-index: 1;
}
   
#container button:before {
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: '';
    background-color: #725AC1;
    z-index: -1;
}
   
#container button:hover, button:focus {
    color: #F8F8F8;
}
   
#container button:hover:before, button:focus:before {
    transition: 0.5s all ease;
    left: 0;
    right: 0;
    opacity: 1;
}
   
#container button:active {
    transform: scale(0.9);
}