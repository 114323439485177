#createCont{
    position: sticky;
    top: 32vh;
    display: flex;
    flex-direction: row;
    background-color: transparent;

    width: 20%;
    height: 40vh;
}

#openCreate{
    position: relative;
    left: -60.6vw;
    top: -25vh;

    transition: 0.5s;

    width: 50vw;
    height: 80vh;

    padding: 20vh 5vw 8vh 5vw;
    border-radius: 5vh;

    background-color: #11101F;
}

#closeCreate{
    position: relative;
    left: -60.6vw;
    top: -100vh;

    transition: 0.5s;

    width: 50vw;
    height: 60vh;

    padding: 0vh 5vw 8vh 5vw;
    border-radius: 5vh;
}

#innerCreateCont{
    width: 41vw;
    height: fit-content;
    max-height: 70vh;

    padding: 3vh 2vw 3vh 2vw;
    padding-bottom: 0;

    border-left: 0.1vw solid rgba(255, 217, 0, 0.5);
    border-right: 0.1vw solid rgba(255, 217, 0, 0.5);
    border-radius: 1vw;
    background-color: #19182D;

    overflow-y: scroll;
}




.infoCont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 38vw;
    height: 10vh;
    margin: 1vh auto 1vh auto;
}
.labelInputTitle{
    width: 36vw;
    margin: 1vh auto 1vh auto;

    color: #F0E9D2;
    background-color: transparent;

    box-shadow: 0 0 inset #864879;

    border: none;

    font-size: 4vh;
    text-align: center;

    transition: 0.2s;
}
.labelInputTitle:focus{
    outline: none;

    border-bottom: 0.2vh solid #E9A6A6;

    box-shadow: 0 -2vh inset #864879;
    transition: 0.2s;
}




.labelInputDescription{
    width: 38vw;
    height: 6vh;
    margin: 1vh auto 1vh auto;

    color: #F0E9D2;
    background-color: transparent;
    box-shadow: 0 -0vh inset #864879;

    border: none;

    resize: none;

    transition: 0.2s;
}
.labelInputDescription::placeholder{
    text-align: center;
    font-size: 2vw;
}
.labelInputDescription:focus{
    outline: none;
    border-bottom: 0.2vh solid #E9A6A6;

    box-shadow: 0 -2vh inset #864879;

    transition: 0.2s;
}

.infoContCat{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 38vw;
    height: fit-content;
    margin: 1vh auto 1vh auto;

    color: #F0E9D2;

    transition: 0.2s;
}
.labelInputCat{
    width: 20vw;
    height: 4vh;
    margin: 1vh auto 1vh auto;

    color: #F0E9D2;
    background-color: transparent;
    box-shadow: 0 -0vh inset #864879;

    border: none;
    border-bottom: 0.2vh solid #E9A6A6;

    font-size: 1.2vw;
    text-align: center;

    cursor: pointer;

    transition: 0.2s;
}
.labelInputCat:focus{
    outline: none;
    box-shadow: 0 -2vh inset #864879;
}
.optionCat{
    background-color: #19182D;
}
.selectedCont{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    width: 36vw;
    height: fit-content;
    padding: 1vh 0 1vh 0;
}
.categorySelected{
    border: none;

    background-color: transparent;

    color: #E3B04B;
    background-color: transparent;

    text-decoration: underline;
    font-size: 1.2vw;

    cursor: pointer;
}
.categorySelected::after{
    position: relative;
    top: -10px;
    width: 100%;
    height: 10px;
    background-color: #864879;
    z-index: 3;
}
.categorySelected > svg{
    color: #E9A6A6;
}


#imgUpCont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 20vw;
    height: fit-content;
    margin: auto;

    color: #F0E9D2;


}

#imgUpHidden{
    max-width: 16vw;
    max-height: 15vh;

    margin-bottom: 2vh;

    overflow: hidden;
}
#imgUploaded{
    height: 15vh;
    width: auto;
}

.labelImgUpload{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 30vw;
    height: 5vh;

    border-radius: 1vw;

    background-color: #864879;
    box-shadow: 0 0 0 0 inset #E9A6A6;

    text-align: center;
    font-size: 1.4vw;
    font-weight: bolder;
}

.labelInputImg{
    position: relative;
    top: -3vh;

    width: 30vw;
    height: 5vh;

    opacity: 0;
    cursor: pointer;
}



#submitButPost{
    width: 70%;
    height: 4vh;
    margin: 2vh 15% 0 15%;

    border: 0.1vw solid #E9A6A6;
    border-bottom: none;
    border-top-left-radius:1vw;
    border-top-right-radius:1vw;

    background-color: transparent;
    box-shadow: 0 0 #725AC1 inset;
    color: #F0E9D2;

    font-size: 1.2vw;

    transition: 0.4s;
}
#submitButPost:hover{
    width: 100%;
    height: 4vh;
    margin: 2vh 0 0 0;

    border-top-left-radius:0.2vw;
    border-top-right-radius:0.2vw;
    border: 0vw solid #E9A6A6;

    box-shadow: 0 -5vh #E9A6A6 inset;
    background-color: #864879;
    color: #F8F8F8;

    transition: 0.4s;
}



.labelSpan{
    color: #E9A6A6;
}

#CreateButShow{
    position: relative;
    top: 5vh;

    width: 16vw;
    height: 15vh;
    margin: auto;
    margin-top: 0;


    border: none;
    border-top-right-radius: 2vw;
    border-bottom-right-radius: 2vw;

    background-color: #864879;
    color: #E9A6A6;

    font-size: 2.5vw;

    cursor: pointer;

    transform: perspective(10px);
}
#CreateButShow > svg{
    padding-bottom: 0;

    transition: 0.5s;
}
#CreateButShow:hover > svg{
    padding-bottom: 2vh;

    transition: 0.5s;
}
#CreateButShow:hover > #AngleUpUp{
    top: -5vh;

    transition: 0.5s;
}
#CreateButShow:hover > #AngleUpDown{
    position: relative;
    top: -1vh;
}

#CreateButShow:hover > #rocketUp{
    position: relative;
    top: -7vh;

    filter: drop-shadow( 0px 3vh 2px #864879);

    transform: perspective(10px) translateZ(2px) rotate(-45deg);

    transition: 0.4s;
}
#rocketUp{
    position: relative;
    top: 1vh;
    transform: rotate(-45deg) perspective(10px) translateZ(0px);

    filter: drop-shadow( 0px 0.5vh 2px #864879);

    transition: 0.5s;
}
#AngleUpUp{
    position: relative;
    top: 1vh;

    transition: 0.5s;
}
#chevronUp{
    position: relative;
    top: -3.5vh;
}
#AngleUpDown{
    position: relative;
    top: -5.5vh;
}




#premiumChoice{
    display: flex;
    flex-direction: row;

    width: fit-content;
    height: 4vh;

    margin: 0 auto 3vh auto;
}
#premiumChoice > p{
    margin: auto;
    margin-right: 1vw;

    color: #F0E9D2;

    font-size: 1.4vw;
}
#SliderShellOn, #SliderShellOff{
    display: flex;

    width: 4vw;
    height: 4vh;

    border-radius: 2vh;

    cursor: pointer;
    transition: 0.4s;
}
#SliderShellOn{
    background-color: #E9A6A6;

}
#SliderShellOff{
    background-color: #3F3351;
}

#sliderOn, #sliderOff{
    position: relative;
    top: 0.25vh;

    height: 3.5vh;
    width: 3.5vh;
    margin: 0 0.2vw 0 0.2vw ;

    border-radius: 3.5vh;

    background-color: white;

    transition: 0.4s;
}
#sliderOn{
    left: 1.9vw;
}
#sliderOff{
    left: 0;
}






@media screen and (max-width:1024px){
    #createCont{
        position: relative;
        top: -10vh;
        width: 20%;
        height: 4vh;
    }

    #openCreate{
        position: relative;
        left: -0vw;
        top: 10vh;

        width: 90vw;
        height: 80vh;
    
        padding: 10vh 5vw 8vh 5vw;
        border-radius: 5vh;
    

        z-index: 2;
    }
    
    #closeCreate{
        position: relative;
        left: -0vw;
        top: -0vh;
    
        transition: 0.5s;
    
        width: 50vw;
        height: 60vh;
    
        padding: 0vh 5vw 8vh 5vw;
        border-radius: 5vh;

        z-index: -1;
    }

    #innerCreateCont{
        position: relative;
        top: -1vw;
        width: 80vw;
        height: fit-content;
        z-index: 2;
    }




    .infoCont{
        width: 76vw;
    }
    .labelInputTitle{
        width: 76vw;
    }


    .labelInputDescription{
        width: 78vw;
        font-size: 2.5vw;
    }


    .infoContCat{
        width: 70vw;
    }
    .labelInputCat{
        width: 70vw;
        font-size: 2vw;

    }
    .optionCat{
        width: 70vw ;
        font-size: 2.5vw;
    }
    .selectedCont{
        width: 76vw;
    }
    .categorySelected{
        font-size: 2vw;
    }


    .labelImgUpload{
        width: 70vw;
        font-size: 2.5vw;
    }







    #premiumChoice{
        height: 40px;
    }
    #premiumChoice > p{
        font-size: 2.5vw;
    }

    #SliderShellOn, #SliderShellOff{
        width: 90px;
        height: 50px;
        border-radius: 25px;
    }

    #sliderOn, #sliderOff{
        position: relative;
        top: 5px;

        height: 40px;
        width: 40px;
        margin: 5px;
        margin-top: 0;

        border-radius: 20px;
    }
    #sliderOn{
        left: 40px;
    }
    #sliderOff{
        left: 0;
    }



    #submitButPost{
        font-size: 2.5vw;
    }




    #slimBut{
        position: relative;
        top: 5vh;

        width: 10vw;
        height: 5vh;
        
        border: none;
        border-top-right-radius: 1vw;
        border-bottom-right-radius: 1vw;
    
        background-color: #864879;
        color: #E9A6A6;

        font-size: 3vw;
        text-align: center;

    }
}