
#decoCont{
  width: 20vw;
  margin: 10vw;

  text-align: center;

  color: #864879;
}
#decoCont > h1{
  margin-top: 0;
  font-size: 3vw
}
#landingImg{
  width: 20vw;
  height: auto;
  
}

#registerCont{
  display: flex;
  justify-content: center;
  align-items: center;


  width: 100vw;
  height: 100vh;


}

#formRegister{ 
  width: fit-content;
  height: fit-content;

  padding: 3vw;

  border-radius: 1vw;
  /* border: 0.5vw solid #864879; */
  box-shadow: 0vw 0vh 1vh 1vh #6000947b;
  border-bottom: 0.2vh solid #6000945e;

  background-color: #1F1D36;
  
}

.inputTextCont{
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  width: 30vw;
  height: fit-content;
  margin: 0vh auto 2vh auto;
}
.inputTextCont > label{
  width: 15vw;
  height: 3vh;
  margin-bottom: 1vh;

  color: #F0E9D2;

  font-size: 3vh;
}

.inputText{
  height: 4vh;
  margin-bottom: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;

  border-radius: 0.5vw;
  border: 0.1vw solid #864879;

  color: #F0E9D2;
  background-color: transparent;

  font-size: 1.2vw;

  transition: 1s;
}
.inputText:focus{
  outline: none;
  border: 0.1vw solid #E9A6A6;

  transition: 1s;
}

.error{
  color: #E9A6A6;
}

#submitBut{
  width: 100%;
  height: 4vh;
  margin-bottom: 2vh;

  border: 0.1vw solid #864879;
  border-bottom-left-radius: 0.5vw;
  border-bottom-right-radius: 0.5vw;

  box-shadow: 0 0vh 0 0 #864876 inset;
  background-color: transparent;
  color: #F0E9D2;

  transition: 0.5s;
}
#submitBut:hover{
  box-shadow: 0 5vh 0 0 #864876 inset;
  color: #F8F8F8;
  transition: 0.5s;
}

.selectCategories{
  height: 4vh;

  border-radius: 0.5vw;
  border: 0.1vw solid #864879;

  color: #F0E9D2;
  background-color: transparent;

  font-size: 1.4vw;

  transition: 1s;
}
.selectCategories::placeholder{
  text-align: center;
}
.selectCategories:focus{
  outline: none;
}

.categoriesOption{
  background-color: #1F1D36;
}

.selectedCont{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  width: 30vw;
  height: fit-content;
  padding: 1vh 0 1vh 0;
}
.categorySelected{
  border: none;

  background-color: transparent;
  
  color: #E3B04B;
  background-color: transparent;

  text-decoration: underline;
  font-size: 1.2vw;

  cursor: pointer;
}
.categorySelected > svg{
  color: #E9A6A6;

}


#backLink{
  text-decoration: none;
}
#backBut{
  /* position: relative;
  top: -45vh;
  left: -5vw; */

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 10vw;
  height: 5vh;

  border: none;
  border-bottom: 0.2vw solid #864879;
  border-radius: 1vw;

  color: #F0E9D2;
  background-color: #11101F;


  font-size: 1.3vw;
  font-weight: bold;
  text-decoration: none;

  cursor: pointer;
  transition: 0.5s;

}
#backBut:hover{
  box-shadow: -10vw 0vh 0 0 #864876 inset;

  transition: 0.5s;
}
#backBut > svg{
  position: relative;
  left: -0vw;
  transition: 0.5s;
}
#backBut:hover > svg{
  position: relative;
  left: -2vw;

  transition: 0.5s;
}