.CardPostCont{
    width: 40vw;
    height: fit-content;
    margin: 5vh auto 5vh auto;

    /* box-shadow: 0 0 2vh 2vh #150050; */
    border-radius: 1vw;

    background-color: #19182D;

    border-left: 0.1vw solid rgba(255, 217, 0, 0.5);
    border-right: 0.1vw solid rgba(255, 217, 0, 0.5);       
}

.userInfoCont{
    position: relative;
    left: -1%;

    display: flex;
    flex-direction: row;
    
    width: 100%;
    height: 6vh;
    padding: 0 1% 0 1%;


    text-align: center;

    border-top-left-radius: 1vw;
    border-top-right-radius: 1vw;
    box-shadow: 0vw 0.5vh 0.5vh #6000947b;
    border-bottom: 0.2vh solid #6000945e;

    background-color: #1F1D36;
    color: #F8F8F8;
}

.userPhotoCont{
    width:5vh;
    height: 5vh;
    border-radius: 5vh;
    margin: 0.5vh 1vw 0.5vh 1vw ; 
    overflow: hidden;   
}
.userPhoto{
    width: 5vh;
    height: auto;
}
.userName{
    display: flex;
    flex-direction: row;
    height: 6vh;
    width: 35vw;
    margin: auto 0 auto 0;

    text-decoration: none;
    font-weight: bolder;
    font-size: 3vh;

    color: #864879;

    overflow: hidden;

    cursor: pointer;
}
.userNameP{
    width: 30vw;
    height: 3vh;
    margin: 1.5vh 0 1.5vh 0;

    overflow: hidden;
}

.deleteBut{
    display: flex;
    justify-content: center;
    align-items: center;

    width:5vh;

    font-size: 1.2vw;
}


.sharedCont{
    width: 30vw;
    margin: 1vh auto 0 auto;

    color: #F0E9D2;

    font-size: 0.9vw;
    text-align: center;
}
.sharedCont > a{
    color: #E9A6A6;
}

.cardPostTitle{
    height: fit-content;
    width: 36vw;
    margin: 1vh auto 1vh auto;

    color: #F0E9D2;

    font-size: 4vh;
    text-align: center;
}

.descriptionCont{
    width: fit-content;
    max-width: 38vw;
    margin: 1vh auto 1vh auto;

    color: #F0E9D2;
}
.cardPostDescription{
    font-size: 2vh;
}


.imgsCont{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;

    height: 20vw;
    width: 30vw;
    margin: auto;
}
.cardpostImg{
    width: auto;
    height: auto;
    max-height: 20vw;
    margin: 0 2vw 0 2vw;
}

#imgPreviewCont{
    display: flex;
    flex-wrap: wrap;

    width: 36vw;
    margin: auto;

    border-radius: 4vw;

    overflow: hidden;
}
.imgSingleCont{
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0.5%;

    overflow: hidden;
    cursor: pointer;
}

.singleImg{
    width:99%;
    height:99%;
}
.halfImg{
    width:49%;
    height:99%;
}
.quarterImg{
    width:49%;
    height:49%;
}



#categoriesCont{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    width: 30vw;
    height: fit-content;
    margin: 1vh auto 1vh auto;

}
.category{
    width: fit-content;
    margin: 0 0.5vw 0 0.5vw;

    border: none;

    color: #E3B04B;
    background-color: transparent;

    text-decoration: underline;
    font-size: 1.3vw;

    cursor: pointer;
    transition: 0.2s;
}
.category:hover{
    color: #E9A6A6;
}

.cardPostPrice{
    width: fit-content;
    margin: 1vh auto 1vh auto;

    color: #E3B04B;
}


.analiticsCont{
    position: relative;
    left: -1%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    width: 100%;
    height: 5vh;
    padding: 0 1% 0 1%;
    margin-top: 2vh;

    color: #F8F8F8;
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
    box-shadow: 0vw -0.5vh 0.5vh #6000947b;
    border-bottom: 0.2vh solid #6000945e;

    background-color: #1F1D36;
}
.likesShell,.sharesShell,.favoritesShell,.commentShell{
    display: flex;
    justify-content: space-between;
    width: 3vw;
    height: fit-content;
    margin: auto 0 auto 0;
    
    
}


.notLikedImg ,.notSharedImg{
    color: #F0E9D2;
    cursor: pointer;

    transition: 0.5s;
}
.sharedImg{
    color: #E9A6A6;
    cursor: pointer;

    transition: 0.5s;
}
.likedImg{
    color: #E3B04B;
    cursor: pointer;

    transition: 0.5s;
}


.commentShell{
    width: fit-content;
    color: #F0E9D2;
    
}
.commentShell > a {
    color: #F0E9D2;
    text-decoration: none;
}
.commentShell > a > svg{
    color: #F0E9D2;
    font-size: 1.3vw;
}



@media screen and (max-width:1024px){
    .CardPostCont{
        width: 70vw;
        margin: 3vh auto 3vh auto;     
    }

    .userPhotoCont{
        width:5vh;
        height: 5vh;
        border-radius: 10vh;  
    }
    .userPhoto{
        width: 6vh;
    }
    .userName{
        width: 60vw;
    }
    .userNameP{
        width: 50vw;    
        overflow-x: hidden;
    }
    
    .deleteBut{   
        font-size: 2.5vw;
    }
    .sharedCont{
        font-size: 1.8vw;
    }

    .cardPostTitle{
        width: 66vw;
        font-size: 4vw;
    }
    
    .descriptionCont{
        max-width: 68vw;
    }
    .cardPostDescription{
        font-size: 2.5vw;
    }


    .imgsCont{
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
    
        height: 35vw;
        width: 60vw;
        margin: auto;
    }
    .cardpostImg{
        width: auto;
        height: auto;
        max-height: 35vw;
        margin: 0 2vw 0 2vw;
    }
    
    #imgPreviewCont{
        display: flex;
        flex-wrap: wrap;
    
        width: 60vw;
        height: fit-content;
        margin: auto;
    
        border-radius: 4vw;
    
        overflow: hidden;
    }
    .imgSingleCont{
        display: flex;
        align-items: center;
        justify-content: center;
        
        margin: 0.5%;
    
        overflow: hidden;
        cursor: pointer;
    }


    #categoriesCont{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    
        width: 66vw;
        height: fit-content;
        margin: 1vh auto 1vh auto;
    
    }
    .category{
        font-size: 2.5vw;
    }
   
    
    .cardPostPrice{
        font-size: 2vw;
    }


    .likesShell,.sharesShell,.favoritesShell,.commentShell{
        display: flex;
        justify-content: space-between;
        width: 6vw;
        height: fit-content;
        margin: auto 0 auto 0;
        font-size: 3vw;
        
        
    }
    .notLikedImg ,.notSharedImg{
        color: #F0E9D2;
        cursor: pointer;

        
    
        transition: 0.5s;
    }
    .sharedImg{
        color: #E9A6A6;
        cursor: pointer;
    
        transition: 0.5s;
    }
    .likedImg{
        color: #E3B04B;
        cursor: pointer;
    
        transition: 0.5s;
    }
    
    
    .commentShell{
        width: fit-content;
        color: #F0E9D2;
        
    }
    .commentShell > a {
        color: #F0E9D2;
        text-decoration: none;
    }
    .commentShell > a > svg{
        color: #F0E9D2;
        font-size: 1.3vw;
    }
}