#shoppedCont{
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 50vw;
    height: fit-content;
    margin-top: 2vh;
    margin: 2vh auto 0 auto;
}


.wholeCont{
    width: 40vw;
    height: fit-content;

    padding: 0.5vw;
    margin: 1vh auto 1vh auto;

    border-radius: 0.5vw;

    transition: 0.2s;
}
.wholeCont:hover{
    background-color: #151425;
}
.wholeCont:hover > .purchasedShell .purchased{
    border: 3px solid #1F1D36;
}

.shopped{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    width: 30vw;
    height: 15vh;
    margin: 1vh auto 0vh auto;
    
    border-radius: 1vw;
    border: 0.2vw solid #1F1D36;

    background-color: #1F1D36;
    color: #F0E9D2;

    transition: 0.2s;
}
.shopped:hover{
    border-radius: 0.5vw;
    border: 0.2vw solid #1C1A31;

    background-color: #3F3351;
}

.shopped:hover > .UserShell > .userName{
    color: #E9A6A6;
}


.shopped:hover > .arrowShell{
    color: #E3B04B;
}


.shopped:hover > .ticketShell > .amount > span{
    color: #E3B04B;
}
.shopped:hover > .ticketShell > .ticketBut{
    padding: 0.5vh 1vw 0.5vh 1vw;

    border-radius: 0.5vw;
    border: 0.2vw solid #11101F;
    
    /* box-shadow: 0 5vh inset #864879; */
    background-color: #864879;
    color: #F0E9D2;

    transition: 0.2s;
}
.shopped:hover > .ticketShell > .ticketBut > svg{
    left: 0;
    font-size: 1.1vw;
}



.userShell{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 6vw;
    height: fit-content;
}
.imgShell{
    width: 5vw;
    height: 5vw;

    border-radius: 5vw;
    border: 0.2vw solid #864879;

    background-color: #864879;

    overflow: hidden;
}
.img{
    align-self: center;

    max-width: 8vw;
    min-width: 5vw;

    max-height: 10vw;
    min-height: 5vw;
}
.userName{
    width: 100%;
    height: 3vh;
    margin: 0;

    color: #864879;

    text-align: center;
    font-weight: bold;

    overflow-x: hidden;

    transition: 0.2s;
}




.arrowShell{
    display: flex;
    width: 7vw;

    color: #F0E9D2; /*E3B04B*/

    font-size: 2vw;

    transition: 0.2s;
}
.arrowShell > p{
    width: fit-content;
    margin: auto 0.2vw auto 0.2vw;
    font-size: 1.2vw;
}




.ticketShell{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 7vw;
    height: fit-content;

    text-align: center;
}
.amount{
    margin: 0%;
    margin-bottom: 1vh;
}
.amount > span{
    color: #F0E9D2;
}

.ticketBut{
    width: fit-content;
    height: fit-content;
    padding: 0.2vh 0.8vw 0.2vh 0.8vw;

    border-radius: 1vw;
    border: 0.2vw solid #11101F;

    box-shadow: 0 0 0 0 inset #11101F;
    background-color: #1F1D36;
    color: #1F1D36;

    text-align: center;
    text-decoration: none;

    transition: 0.5s;
}
.ticketBut > svg{
    position: relative;
    left: 1.5vw;

    color: #F0E9D2;
    font-size: 1.4vw;

    transition: 0.2s;
}
.shopped:hover > .ticketShell > .ticketBut:hover{
    padding: 0.5vh 1.2vw 0.5vh 1.2vw;

    border-radius: 0.2vw;

    border: 0.2vw solid #E9A6A6;
    
    box-shadow: 0 -5vh inset #E9A6A6;
    background-color: #E9A6A6;
    color: #11101F;

    transition: 0.5s;
}
.shopped:hover > .ticketShell > .ticketBut:hover > svg{
    color: #11101F;
}


.purchasedShell{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40vw;
    height: 30vh;
    
    padding: 1vh 0.5vw 1vh 0.5vw;
    margin: 0 auto 0 auto;

    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
}
.purchased{
    max-width: 70%;
    min-width: 20%;

    max-height: 70%;
    min-height: 20%;

    border-radius: 2vw;
    border: 3px solid #19182D;

    overflow: hidden;

    transition: 0.2s;
}
.wholeCont:hover > .purchasedShell .purchased:hover{
    max-width: 100%;
    max-height: 100%;

    border-radius: 0.5vw;
    border: 3px solid #E9A6A6;
}


#loadMoreBut{
    width: 100%;
    height: 3vh;

    border: none;
    border-bottom-right-radius: 2vw;
    border-bottom-left-radius: 2vw;
    border: 0.1vw solid transparent;
    border-top: 0.1vh solid rgb(63, 51, 81);
    

    box-shadow: inset 0vw 0.2vh 0.5vh rgb(63, 51, 81);
    background-color: #11101F;
    color: #3F3351;

    font-size: 0.8vw;
    
    cursor: pointer;
    transition: ease-out 0.2s; 
}
#loadMoreBut:hover{
    height: 4vh;

    border-radius: 0.1vw;
    border-bottom-right-radius: 1vw;
    border-bottom-left-radius: 1vw;
    border: 0.2vw solid #1C1A31;

    box-shadow: inset 0vw 5vh #3F3351;
    background-color: #E9A6A6;
    color: #F0E9D2;

    font-size: 0.9vw;
    font-weight: bold;
}




@media screen and (max-width:1024px){
    #ShoppedCont{
        width: 90vw;
    }

    .wholeCont{
        width: 90vw;
    }    
    .shopped{ 
        width: 80vw;
    }


    .userShell{
        width: 15vw;
    }
    .imgShell{
        width: 10vw;
        height: 10vw;   
        border-radius: 10vw;

        margin: auto;
    }
    .img{   
        max-width: 25vw;
        min-width: 10vw;
    
        max-height: 10vw;
        min-height: 10vw;
    }
    .userName{
        width: 100%;
        height: 3vh;
        
        font-size: 2.5vw;
    }


    .arrowShell{
        width: 15vw;
    }
    .arrowShell > p{
        margin: auto;
        font-size: 3vw;
    }


    .ticketShell{ 
        width: 20vw;
        font-size: 2.5vw;
    }

    .ticketBut{
       font-size: 4vw;
    }
    .ticketBut > svg{
        left: 5.5vw;
    
        font-size: 4vw;

    }


    .purchasedShell{  
        width: 80vw;
        height: 60vh;
    }
    .purchased{
        max-width: 90%;
        max-height: 90%;
    
        border-radius: 1vw;
    }
    
}