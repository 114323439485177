#homeCont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: fit-content;
    margin-top: 3vh;

}

#filterOrderCont{
    position: sticky;
    top: 17.5vh;
    left: -40vw;
    display: flex;
    flex-direction: row;

    width: 0vw;
    height: 0vh;
    padding: 0;

    z-index: 1;
}

#resetOption{
    height: 4vh;

    border: none;
    border-radius: 1vw;
    border-bottom: 0.2vh solid #150050;
    box-shadow: 0 0.3vh 0.3vh #150050;

    color: #F0E9D2;
    background-color: transparent;
}
#resetOption:hover{
    cursor: pointer;
    color: #F1D6AB;
}


#InfoCont{
    display: flex;
    flex-direction: row;

    width: 100%;
    
    contain: paint;
}


#nextPageBut{
    height: 10vh;
    width: 100%;

    border: none;
    box-shadow: inset 0vw 0.2vh 0.5vh rgb(63, 51, 81);
    border-top: 0.1vh solid rgb(63, 51, 81);
    color: rgb(54, 0, 96);
    background: #00000045;

    font-size: 1.2vw;
    font-weight: bolder;
    text-align: center;
}
#nextPageBut:hover{
    cursor: pointer; 
    
}




@media screen and (max-width:1024px){
    #homeCont{
        margin-top: 0vh;
        
    }
    #InfoCont{
        display: flex;
        flex-direction: column;
    
        width: 100%;
        
        contain: none;
    }

    #filterOrderCont{
        position: relative;
        top: 0;
        left: -45vw;
        display: flex;
        flex-direction: row;
    
        width: 10vw;
        height: 4vh;
        padding: 3vh 0 3vh 3.5vw;
    }
    #filterOrderCont{
        padding: 0vh 0 1vh 0.5vw;
    }
}