#navbarCont{
    position: sticky;
    top: 0;
    z-index: 1;
}

#navbarShell{
    position: relative;

    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 8vh;

    background-color: rgb(31, 29, 54);

    box-shadow: 0vw 0.2vh 0.5vh rgb(63, 51, 81);
    border-bottom: 0.1vh solid rgb(63, 51, 81);

    z-index: 3;
}

#menuIcon{
    width: 4vh;
    height: 8vh;
    color: #F8F8F8;
}
#menuShell,#movedMenuShell{
    position: relative;
    left: -5vw;
    width: 4vh;
    height: 4.1vh;
    margin: 2vh auto 2vh auto;
    font-size: 4.2vh;

    cursor: pointer;
    transition: 0.5s;
}
#movedMenuShell{
    left: -15vw;

    transition: 0.5s;
}


#menuOpen,#menuClosed{
    position: relative;
    left: 19.5vw;

    width: fit-content;

    z-index: 0;
    
}
#menuOpen{
    top: 0;
    transition: 0.5s;

}
#menuClosed{
    top: -6vh;
    transition: 0.5s;
}

#SearchContainer{
    display: flex;
    flex-direction: row;

    width: fit-content;
    height: 4vh;
    margin: auto;
    
    background-color: black;

    border-bottom-left-radius: 1vw;
    border-top-left-radius: 1vw;
    
}
#inputSearchShell{
    height: 4vh;
    width: fit-content;
    margin: auto 1vw auto 0;
}
#searchInput{
    height: 4vh;
    width: 20vw;
    padding-left: 1vw;

    background-color: transparent;
    color: #F0E9D2;

    border: none;

    font-size: 2vh
}
#searchInput:focus{
    outline: none;
}

#inputButtonShell{
    width: fit-content;
    height: fit-content;
    margin: auto 0 auto 0;
}
#searchButton{
    height: 3vh;
    width: 4vw;
    border: none;
    margin-top: 0.1vh;

    background-color: black;
    color: #F8F8F8;
}
#searchButton > p{
    margin: auto;
    font-size: 2vh;
}
#searchLink{
    color: #F8F8F8;

    transition: 0.5s;
}
#searchLink:hover{
    cursor: pointer;
    text-decoration: none;
    color: #F8F8F8;
}

#notificationIcon{
    width: 3vh;
    height: 3vh;
    font-size: 3vh;
    color: #F8F8F8;

    cursor: pointer;
    transition: 0.2s;
}
#notificationIcon:hover{
    color: #E9A6A6;

    transition: 0.2s;
}
#notificationsShell{
    width: 3vh;
    height: 3.1vh;
    margin: 2.5vh  2.5vh ;
    font-size: 3.2vh;

    color: #F0E9D2;
}
#deletNotisBut{
    width: fit-content;
    height: 4vh;
    margin: 2vh auto 2vh auto;

    
    border-radius: 0.5vw;
    border: 0.1vw solid #864879;
    box-shadow: 0 0 #864876 inset;
    
    color: #F0E9D2;
    background-color: transparent;

    transition: 0.2s;
}
#deletNotisBut:hover{
    box-shadow: 0 -4vh #864876 inset;
    color: #F8F8F8;

    transition: 0.2s;
}

#logoutBut{
    width: fit-content;
    padding: 1vh;

    border: 0.1vw solid #864879;
    border-radius: 0.5vw;
  
    box-shadow: 0 0vh #864876 inset;
    background-color: rgb(31, 29, 54);
    color: #F0E9D2;
  
    transition: 0.5s;
  }
#logoutBut:hover{
    box-shadow: 0 5vh #864876 inset;
    color: #F8F8F8;
  
    cursor: pointer;
  
    transition: 0.5s;
  }


#paddingDer{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 20vw;
    height: 8vh;
    background: linear-gradient(90deg, rgb(31, 29, 54) 40%, #131222 76%);
}
#paddingIzq{
    width: 20vw;
    height: 8vh;
    background: linear-gradient(90deg, #131222 24%, rgb(31, 29, 54) 60%);
}


#notiNumber{
    position: relative;
    left: -2vw;
    top: -1vh;

    width: 2vw;
    height: 2vw;

    border-radius: 2vw;

    background-color: #E9A6A6;
    color: #11101F;

    text-align: center;
    font-size: 2vw;
}
#notisCont{
    position: absolute;
    top: 7vh;
    left: 80.5vw;

    display: flex;
    flex-direction: column;

    width: 18.6vw;
    height: fit-content;

    border-radius: 0.5vw;
    box-shadow: 0vw 0.3vh 0.3vh 0.2vh #6000947b;
    border-bottom: 0.2vh solid #6000945e;

    background-color: #151425;
}

#notisCont > span{
    width: 100%;
    height: fit-content;
    padding: 0.5vh 0 0.5vh 0;

    border-top: 0.1vh solid #E9A6A6;

    color: #F0E9D2;

    text-align: center;

}


@media screen and (max-width:1024px){
    #menuOpen,#menuClosed{
        left: 0vw;
    }
    #menuClosed{
        top: -7.5vh;
    }
    #searchLink{
        font-size: 2vw;
    }
    #searchInput{
        width: 40vw;
    }
}