#editCont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: fit-content;
    padding-top: 5vh;
    padding-bottom: 5vh;

    background-color: #11101F;
}

#editForm{
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: fit-content;
    height: fit-content;
    padding: 3vw;
    padding-bottom: 0;


    box-shadow: 0vw 0vh 1vh 1vh #6000947b;
    border-bottom: none;
    border-radius: 1vw;

    background-color: #1F1D36;
}
#rowEditsCont{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
#textEdits{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.editShell{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 30vw;
    height: fit-content;
    margin: 0vh auto 2vh auto;

    text-align: center;
}
.editLabel{
    width: 15vw;
    height: 3vh;
    margin-bottom: 1vh;

    color: #F0E9D2;

    font-size: 3vh;
}

#currentImgProfileCont{
    width: 10vw;
    height: 10vw;
    margin: 1vh 0 2vh 0;

    border-radius: 10vw;
    border: 0.4vw solid #864879;

    background-color: #E9A6A6;

    overflow: hidden;

}
#currentImgProfileCont > div{
    position: relative;
    /* top: -25%; */
    left: -25%;
    width: 15vw;
    height: fit-content;
}
#currentImgProfile{
    width: 100%;
    max-width: 20vw;

    height: auto;
}

#currentImgBackCont{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 30vw;
    height: 9vh;
    margin: 1vh 0 2vh 0;

    border-top-left-radius: 0.2vw;
    border-top-right-radius: 0.2vw;
    border-bottom: 0.4vw solid #864879;

    background-color: #E9A6A6;

    overflow: hidden;
}
#currentImgBack{
    width: 30vw;
    height: auto;
}

.decoFileInput{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 12vw;
    height: 4vh;

    border-radius: 0.5vw;

    background-color: #864879;
    color: #F0E9D2;

    text-align: center;
}

.editInput,.editInputFile{
    height: 4vh;
    margin-bottom: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;

    border-radius: 0.5vw;
    border: 0.1vw solid #864879;

    color: #F0E9D2;
    background-color: transparent;

    font-size: 1.2vw;

    transition: 1s;
}
.editInput:focus{
    outline: none;
    border: 0.1vw solid #E9A6A6;

    transition: 1s;
}
.editInputFile{
    position: relative;
    top: -4vh;

    width: 10vw;

    padding: 0;
    margin: 0;

    opacity:0;
    cursor: pointer;
}


#artistChoice{
    display: flex;
    flex-direction: row;

    width: fit-content;
    height: 4vh;

    margin: 0 auto 3vh auto;
}
#artistChoice > p{
    margin: auto;
    margin-right: 1vw;

    color: #F0E9D2;

    font-size: 1.4vw;
}

#SliderShellOn, #SliderShellOff{
    display: flex;

    width: 4vw;
    height: 4vh;

    border-radius: 2vh;

    cursor: pointer;
    transition: 0.4s;
}
#SliderShellOn{
    background-color: #E9A6A6;

}
#SliderShellOff{
    background-color: #3F3351;
}

#sliderOn, #sliderOff{
    position: relative;
    top: 0.25vh;

    height: 3.5vh;
    width: 3.5vh;
    margin: 0 0.2vw 0 0.2vw ;

    border-radius: 3.5vh;

    background-color: white;

    transition: 0.4s;
}
#sliderOn{
    left: 1.9vw;
}
#sliderOff{
    left: 0;
}


#editSubmit{
    width: 100%;
    height: 4vh;
    margin-bottom: 2vh;

    border: 0.1vw solid #864879;
    border-top-left-radius: 0.5vw;
    border-top-right-radius: 0.5vw;

    box-shadow: 0 0vh 0 0 #864876 inset;
    background-color: transparent;
    color: #F0E9D2;

    transition: 0.5s;
}
#editSubmit:hover{
    box-shadow: 0 5vh 0 0 #864876 inset;
    color: #F8F8F8;
    transition: 0.5s;
}
#editSubiendo{
    margin: 0 auto 0 auto;

    color: #864879;

    font-size: 1.1vw;
    text-align: center;
}

#editUpload{
    width: 30vw;
    height: 3vh;

    box-shadow: 0vw 1vh 1vh 1vh #6000947b;
    border: none;
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;

    color: #F0E9D2;
    background-color: #864879;

    font-size: 1vw;

    transition: 0.5s;
}
#editUpload:hover{
    height: 4vh;
    width: 32vw;
    font-size: 1.1vw;
}

.btn{
    border: 3px solid #1a1a1a;
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;
}
#passwordCont{
    height: fit-content;
    width: fit-content;
    padding: 2.5vh 0;
    border-radius: 0.5vw;
    border: 0.2vw solid #864879;
    z-index: 100;
}
.error{
    color: #E9A6A6;
  }

  .inputTextCont{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  
    width: 30vw;
    height: fit-content;
    margin: 0vh auto 2vh auto;
  }
  .inputTextCont > label{
    width: 15vw;
    height: 3vh;
    margin-bottom: 1vh;
  
    color: #F0E9D2;
  
    font-size: 3vh;
  }
  
  .inputText{
    height: 4vh;
    margin-bottom: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
  
    border-radius: 0.5vw;
    border: 0.1vw solid #864879;
  
    color: #F0E9D2;
    background-color: transparent;
  
    font-size: 1.2vw;
  
    transition: 1s;
  }
  .inputText:focus{
    outline: none;
    border: 0.1vw solid #E9A6A6;
  
    transition: 1s;
  }
  
  .error{
    color: #E9A6A6;
  }
  
  #submitBut{
    width: 100%;
    height: 4vh;
    margin-bottom: 2vh;
  
    border: 0.1vw solid #864879;
    border-bottom-left-radius: 0.5vw;
    border-bottom-right-radius: 0.5vw;
  
    box-shadow: 0 0vh 0 0 #864876 inset;
    background-color: transparent;
    color: #F0E9D2;
  
    transition: 0.5s;
  }
  #submitBut:hover{
    box-shadow: 0 5vh 0 0 #864876 inset;
    color: #F8F8F8;
    transition: 0.5s;
  }
  
  .selectCategories{
    height: 4vh;
  
    border-radius: 0.5vw;
    border: 0.1vw solid #864879;
  
    color: #F0E9D2;
    background-color: transparent;
  
    font-size: 1.4vw;
  
    transition: 1s;
  }
  .selectCategories::placeholder{
    text-align: center;
  }
  .selectCategories:focus{
    outline: none;
  }
  
  .categoriesOption{
    background-color: #1F1D36;
  }
  
  .selectedCont{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  
    width: 30vw;
    height: fit-content;
    padding: 1vh 0 1vh 0;
  }
  .categorySelected{
    border: none;
  
    background-color: transparent;
    
    color: #E3B04B;
    background-color: transparent;
  
    text-decoration: underline;
    font-size: 1.2vw;
  
    cursor: pointer;
  }
  .categorySelected > svg{
    color: #E9A6A6;
  
  }
  
  
  #backLink{
    text-decoration: none;
  }
  #backBut{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  
    width: 10vw;
    height: 5vh;
  
    border: none;
    border-bottom: 0.2vw solid #864879;
    border-radius: 1vw;
  
    color: #F0E9D2;
    background-color: #11101F;
  
  
    font-size: 1.3vw;
    font-weight: bold;
    text-decoration: none;
  
    cursor: pointer;
    transition: 0.5s;
  
  }
  #backBut:hover{
    box-shadow: -10vw 0vh 0 0 #864876 inset;
  
    transition: 0.5s;
  }
  #backBut > svg{
    position: relative;
    left: -0vw;
    transition: 0.5s;
  }
  #backBut:hover > svg{
    position: relative;
    left: -2vw;
  
    transition: 0.5s;
  }
  
@media screen and (max-width:1024px){
    #rowEditsCont{
        flex-direction: column;
    }

    .editShell{
        width: 60vw;
    }
    .editLabel{
        width: 50vw;
    }
    .editInput,.editInputFile{
        height: 4vh;
        width: 60vw;

        font-size: 2.5vw;
    }
    .decoFileInput{
        width: 60vw;
        font-size: 2.5vw;
    }


    #currentImgProfileCont{
        width: 20vw;
        height: 20vw;
        border-radius: 20vw;
    }
    #currentImgProfileCont > div{
        left: -50%;
        width: 30vw;
    }
    #currentImgProfile{
        max-width: 30vw;
    }
    #currentImgBackCont{
        width: 56vw;

    }
    #currentImgBack{
        width: 56vw;
        height: auto;
    }



    #artistChoice{
        height: 40px;
    }
    #artistChoice > p{
        font-size: 2.5vw;
    }

    #SliderShellOn, #SliderShellOff{
        width: 90px;
        height: 50px;
        border-radius: 25px;
    }

    #sliderOn, #sliderOff{
        position: relative;
        top: 5px;

        height: 40px;
        width: 40px;
        margin: 5px;
        margin-top: 0;

        border-radius: 20px;
    }
    #sliderOn{
        left: 40px;
    }
    #sliderOff{
        left: 0;
    }


    #editSubmit{
        font-size: 3vw;
    }
    #registerCont{
        flex-direction: column;  
      }
    
      #backBut{
        width: 50vw;
    
        font-size: 2.5vh;
      }
    
    
      #decoCont{
        width: 20vw;
        margin: 2vh;
      
        text-align: center;
      
        color: #864879;
      }
      #decoCont > h1{
        margin-top: 0;
        font-size: 3vw
      }
      #landingImg{
        width: 20vw;
        height: auto;
        
      }
    
    
      .inputTextCont{
        width: 50vw;
        height: fit-content;
        margin: 0vh auto 2vh auto;
      }
      .inputTextCont > label{
        width: 40vw;
        height: 3vh;
        margin-bottom: 1vh;
      
        color: #F0E9D2;
      
        font-size: 3vh;
      }
      .inputText{
        font-size: 3vw;
      }
      .error{
        font-size: 2.5vw;
      } 
      #submitBut{
        font-size: 3vw;
      }
      .selectCategories{
        font-size: 3vw;
      }
      .categorySelected{
        font-size: 3vw;
      }
      .categoriesOption{
        font-size: 2vw;
      }
}