#CategoriesContainer{
    position: sticky;
    top: 17.5vw;
    
    width: 16vw;
    height: 60vh;

    margin: 2vh auto auto auto;

    border-radius: 0.5vw;

    background-color: #1F1D36;
}

#categoriesH2{
    position: relative;
    left: -1%;

    width: 100%;
    padding: 1vh 1% 1vh 1%;
    margin: 0;

    box-shadow: 0vw 0.5vh 0.5vh #6000947b;
    border-bottom: 0.2vh solid #6000945e;
    border-top-left-radius: 0.5vw;
    border-top-right-radius: 0.5vw;

    background-color: #1F1D36;
    color: #F0E9D2;
    

    font-size: 1.5vw;
    text-align: center;
    
}

#selectedCategory{
    display: flex;
    flex-direction: row;

    width: fit-content;
    height: 2.5vh;
    padding: 0.5vh 0.5vw 0.5vh 0.5vw;
    margin: 1vh auto 1vh auto;

    border-radius: 2.5vh;

    background-color: #3c3245;
    color: #F0E9D2;
}
#selectedCategory > p{
    height: 2vh;
    margin: 0;
    margin-left: 0.5vw;
}
#selectedCategory > p:hover{
    cursor: pointer;
}

.categoryStatus{
    width: 15vw;
    margin: auto;       
    margin-top: 5vh;

    color: #F0E9D2;
    font-size: 1.4vw;
    text-align: center;
}


#categoriesArrCont{
    width: 16vw;
    height: 55vh;
    margin: 0vh auto 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    box-shadow: 0vh -2vh 2vh 0 #11101F inset;
    background-color: #19182D;

}
#categoriesArrCont::-webkit-scrollbar{
    display: none;
}

.category{
    width: fit-content;
    height: fit-content;

    padding: 0.6vh;

    border: none;

    color: #E3B04B;
    background-color: transparent;

    text-decoration: underline;
    font-size: 1.3vw;

    cursor: pointer;
    transition: 0.2s;
}
.category:hover{
    color: #E9A6A6;
    transition: 0.2s;
}


@media screen and (max-width:1024px){
    #CategoriesContainer{
        position: relative;
        top: -6vh;

        display: flex;
        flex-direction: row;
        
        width: 90%;
        height: 6vh;
    
        margin:0;
        margin-left: 10vw;
    }
    #categoriesH2{  
        position: relative;
        left: 0%;

        width: 15vw;
        height: fit-content;
        padding: 0;
        margin: auto 2vw auto 2vw;
    
        box-shadow: 0vw 0.5vh 0.5vh transparent;
        border-bottom: 0.2vh solid transparent;
        border-right: 0.3vw solid #3c3245;
        border-top-right-radius: 0vw;
    
        font-size: 2.5vw;       
    }

    #categoriesArrCont{
        width: 85vw;
        height: 6vh;
        margin: 0vh auto 0 auto;
    
        display: flex;
        flex-direction: row;
        justify-content: inherit;
        align-items: inherit;
    
        box-shadow: -2vh 0vh 2vh  #11101F inset;
        background-color: #19182D;
    
        overflow: scroll;
    }
    .category{
        font-size: 2vw;
        margin: auto 1vw auto 1vw;
        padding: auto;
        
    }
}