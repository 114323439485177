#container{
    display: flex;
    flex-wrap: wrap;

    height: fit-content;
    width: 60vw;
    margin: 0 auto 0 auto;
    padding-top: 5vh;
    
    row-gap: 2vh;
    column-gap: 2vw;
    
    color: white;
}
#card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    height: 4vw;
    width: 18vw;
    margin: 0 0.33vw 0 0.33vw;

    /* background-color: rgba(68, 68, 68, 0.248); */
}

.linkFollowShell{
    display: flex;
    flex-direction: row;
    width: 70%;

    text-decoration: none;
}

.linkFollowShell div,#card div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: auto;
}
.linkFollowShell img,#card img{
    width: 4vw;
    height: 4vw;

    border-radius: 2vw;
}

#card button {
    display: inline-block;

    height: 2vw;
    width: 5vw;
    margin: auto 0 auto 0;

    border-radius: 1vw;
    border: 2px solid #1C1A31;
    box-shadow: inset 0 0 0 0 #725AC1;

    color: #725AC1;
    background: transparent;

    text-align: center;
    font-size: 0.8vw;
    font-weight: bold;

    cursor: pointer;
    transition: ease-out 0.5s;
}
   
#card button:hover {
    color: #1C1A31;
    box-shadow: inset 0 -100px 0 0 #E9A6A6;
}
   
#card button:active {
    transform: scale(0.9);
}

.followName{
    color: #F0E9D2;

    font-size: 1.2vw;
    text-align: left;
    overflow-x: hidden;
}
.followUsername{
    color: #a9a596;
    font-size: 0.9vw;
}

#status{
    width: fit-content;
    height: fit-content;
    margin: auto;

    color: #F0E9D2;
    
    font-size: 3vw;
}